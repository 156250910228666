import React from 'react';


class Abstract extends React.Component {
  
  render() {
    return (
       <div className="publicationAbstract">{this.props.abstract}</div>
      );
  }
}


class Item extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      abstract: "",
      show: false
    }
    this.toggleAbstract = this.toggleAbstract.bind(this);
  }


  displayIfExists(my_key, html) {
    if (my_key in this.props.details){
      return html;
    }
    return "";
  }


  toggleAbstract() {
        console.log("toggle");
        this.setState({
          abstract: this.props.details.abstract,
          show: !this.state.show
        });
  }

	render() {

    const abs = this.state.show ? this.state.abstract : "";
    const linkBtn_html = <li><button onClick={event => window.location.href=this.props.details.publisherLink}>link</button></li>;
    const pdfBtn_html = <li><button onClick={event => window.location.href=this.props.details.pdfLink}>pdf</button></li>
    const abstractBtn_html = <li><button onClick={this.toggleAbstract}>abstract</button></li>

    const linkBtn = this.displayIfExists("publisherLink", linkBtn_html); 
    const pdfBtn = this.displayIfExists("pdfLink", pdfBtn_html); 
    const abstractBtn = this.displayIfExists("abstract", abstractBtn_html); 


		return (
			<li className = "publication">
        {this.props.details.author} ({this.props.details.year}) <a href={this.props.details.pdfLink}>{this.props.details.title}</a> {this.props.details.proc}
        <ul className = "publicationMore">
          {linkBtn} {pdfBtn} {abstractBtn}
        </ul>
        <Abstract abstract={abs} />
      </li>
			);
	}
}


class Publications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pubs: []
    };
  }

  componentDidMount() {  
    fetch('data/publications.json')
      .then(response => response.json())
      .then(data => {
        this.setState({ pubs: data.data });
      });
    }

  	render() {
    	return (
      	<div>
          <h2>Publications</h2>  
          <p>View my <a href="https://scholar.google.com/citations?user=QjHF6VsAAAAJ&hl=en">Google Scholar profile.</a></p>
          <ul className="publication">
          {this.state.pubs.map(function(paper,i) {
            return <Item key={i} details={paper} />
            })}
          </ul>
      	</div>
    	);
  	}
}


export default Publications;