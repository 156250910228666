import React from 'react';

class MenuItem extends React.Component {
	render() {
		return (
			<li id={this.props.title}><a href={this.props.href}>{this.props.title}</a></li>
			);
	}
}

class Menu extends React.Component {
  render() {
    return (
      <nav>
        <ul>
        	<MenuItem title="Medium" href="https://medium.com/@johnrooksby"/>
        	<MenuItem title="Twitter" href="http://www.twitter.com/johnrooksby"/>
        </ul>

      </nav>
    );
  }
}


export default Menu;