import React from 'react';

class Item extends React.Component {

  displayIfExists(my_key, html) {
    if (my_key in this.props.item){
      return html;
    }
    return "";
  }

  displayImgIfExists() {
    if ("img" in this.props.item){

      let src, alt = "";

      if ("src" in this.props.item.img){
        src = this.props.item.img.src;
      }
      if ("alt" in this.props.item.img){
        alt = this.props.item.img.alt;
      }

      return <img src={"img/" + src} alt={alt}/>

    } 
    return "";
  }

	render() {

    const link = this.displayIfExists("link", <p className="bottom-right"><a href={this.props.item.link}>Details</a></p>);
    const title = this.displayIfExists("title", <h3>{this.props.item.title}</h3>);
    const text = this.displayIfExists("text", <p>{this.props.item.text}</p>);
    const funder = this.displayIfExists("funder", <span>Funder: {this.props.item.funder}<br/></span>);
    const years = this.displayIfExists("years", <span>Dates: {this.props.item.years}<br/></span>);
    const role = this.displayIfExists("role", <span>Role: {this.props.item.role}</span>);
    const funderYearsRole = <p>{funder}{years}{role}</p>;
    const category = this.displayIfExists("category", <p className="badge">{this.props.item.category}</p>);

    const img = this.displayImgIfExists();

		return (
			<section className="item">
				{img}{category}{title}{text}{funderYearsRole}{link}
			</section>
			);
	}
}

class LoadMore extends React.Component {

  render() {
    return(
        <button onClick={this.props.showMoreRows}>
          load more...
        </button>
      );
  }
}

class DisplayItems extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      json: "data/" + this.props.json,
      rows: 1
    };
    this.showMoreRows = this.showMoreRows.bind(this)
  }

  componentDidMount() {
    fetch(this.state.json)
      .then(response => response.json())
      .then(data => {
        this.setState({ data: data.data });
      });
  }

  showMoreRows() {
    
    this.setState(state => ({
      rows: state.rows+1
    }));
  }


  fetchItems() {
      let itemsArray = [];

      itemsArray = this.state.data.map(function(item,i) {
          return <Item key={i} item={item} />
        })
      return itemsArray;
    }


  render() {

    let allItems = this.fetchItems();
    let itemsToShow = allItems.slice(0,(this.state.rows * this.props.cols));
    let moreToLoad = (itemsToShow.length < allItems.length) ? <LoadMore showMoreRows={this.showMoreRows}  /> : "";
    return (
      <div>
        <div className={"items" + this.props.cols}>
          {itemsToShow}
        </div>
          {moreToLoad}
      </div>
    );
  }
}

class DisplayProjects extends React.Component {

   render() {
    return (

    <div>
        <h2>Projects</h2>
          <p>
            My research is in Human Computer Interaction and Digital Health. I am interested in the design and evaluation of interactive systems, the role of technology in everyday life, and technology for health and wellbeing. 
          </p>
          <p>
            At Northumbria I am a member of the <a href="http://northlab.uk">NorthLab</a> and <a href="http://nor.sc">NorSC</a> research groups. 
          </p>
      <DisplayItems json="projects.json" heading="Other projects" cols="3" />
    </div>
    );
  }
}


class DisplaySupervision extends React.Component {

  render() {
    return (
      <div>
        <h2>Supervision</h2>
        <p>I am happy to hear from potential PhD students interested in Human Computer Interaction and Digital Health. Funding opportunities are sometimes available.</p>
        <p>Previous student:</p>
          <ul><li><a href = "https://clairemccallum.wordpress.com/about/">Dr Claire McCallum</a> - Thesis: <a href="http://theses.gla.ac.uk/72978/">Evaluating the impact of physical activity apps and wearables: an interdisciplinary investigation of research designs and methods</a></li></ul>
        {/*<DisplayItems json="supervision.json" heading="HI HIH Supervision" />*/}
      </div>
    );
  }
}

class DisplayTeaching extends React.Component {

  render() {
    return (

      <div>
        <h2>Teaching</h2>
        <p>My teaching is mainly focused on Web Development.</p>
        <p>In 2019 I gained Fellowship of the Higher Education Academy (FHEA).</p>
        <DisplayItems json="teaching.json" heading="Teaching" cols="3" />
      </div>

    );
  }
}

export { DisplaySupervision, DisplayTeaching, DisplayProjects};


